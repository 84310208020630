.sct-full {
  display: flex !important;
  top: 0px !important;
  left: 0px !important;
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.sct-loader > div > p {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.5);
}
