.dndflow {
  flex-direction: row;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-left: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  width: 300px;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .node-sidebar-node {
  height: 40px;
  padding: 4px;
  background: #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  background: #0041d0;
}

.dndflow .dndnode.output {
  background: #ff0072;
}

/* To identifie selected node */
.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}
/* To identifie selected edge */
.dndflow .selectall {
  margin-top: 10px;
}
