@import "~@blueprintjs/core/lib/scss/variables";

html,
body,
body > #root,
body > #root > div {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body > #root > div {
  color: white;
  font-family: sans-serif;
  font-size: 18px;
}

.nav-and-side {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 6fr auto;
  grid-template-rows: 50px min-content auto;
  grid-template-areas:
    "nav nav"
    "subnav subnav"
    "content side";
}

.subnav {
  grid-area: subnav;
}

.content {
  grid-area: content;
  overflow: auto;
}

.side {
  grid-area: side;
}

.side-300 {
  width: 300px;
}

.side-500 {
  width: 500px;
}

.side-700 {
  width: 700px;
}

.hide {
  display: none;
  /* width: 0 !important; */
}

.full-screen {
  display: grid;
  grid-template-columns: auto 400px auto;
  grid-template-rows: auto 80% auto;
  grid-template-areas:
    ". ....... ."
    ". content ."
    ". ....... .";
}

.full-screen > .nav,
.full-screen > .side {
  display: none;
}

.full-screen > .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.fluid {
  width: 100%;
}

.sct-monitor-container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 96px auto 32px;
  grid-template-rows: 45px auto;
  grid-template-areas:
    "sct-monitor-left sct-monitor-tabs sct-monitor-right"
    "sct-monitor-content sct-monitor-content sct-monitor-content";
}

.sct-monitor-left {
  grid-area: sct-monitor-left;
}

.sct-monitor-right {
  grid-area: sct-monitor-right;
}

.sct-monitor-tabs {
  grid-area: sct-monitor-tabs;
  overflow-x: auto;
  overflow-y: hidden;
}

.sct-monitor-content {
  grid-area: sct-monitor-content;
  overflow: auto;
}

.sct-sheet-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2px;
  height: 36px;
}

.sct-button-in-button {
  cursor: context-menu;
  padding-left: 5px;
  padding-right: 3px;
  margin: 0 !important;
}

.sct-multistep-dialog {
  min-height: 200px;
}

// To override overflow browser style (https://www.digitalocean.com/community/tutorials/css-scrollbars)
/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $dark-gray5 $dark-gray1;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: $dark-gray1;
}

*::-webkit-scrollbar-thumb {
  background-color: $dark-gray5;
  border-radius: 6px;
}
