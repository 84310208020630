/* Blueprintjs table override */
.bp5-table-cell {
  font-size: 14px;
}

.sct-table {
  table-layout: auto;
  width: 100%;
}

.sct-td-name,
.sct-th-name {
  width: 160px;
}

.sct-td-select,
.sct-th-select {
  text-align: center !important;
  width: 120px;
}

.sct-td-checkbox {
  text-align: center !important;
  width: 100px;
}
.sct-th-checkbox {
  text-align: right !important;
  width: 100px;
}

.bp5-popover-wrapper.sct-fullwidth,
.bp5-popover-wrapper.sct-fullwidth > .bp5-popover-target {
  display: block;
}

.bp5-popover.bp5-popover-content-sizing .bp5-popover-content {
  max-width: 625px;
}

.sct-tbody-overflow {
  display: block;
  max-height: 450px;
  width: 600px;
  overflow: auto;
}
.sct-thead {
  display: block;
  width: 580px;
}
