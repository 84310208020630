.main-container {
  display: flex;
  flex-direction: column;
}

.top-container {
  border-bottom: 2px solid rgba(16, 22, 26, 0.2);
  /* height: 100px; */
  /* padding-bottom: 20px; */
  /* background-color: #0b5ab0; */
  /* color: white; */
}

.content-wrapper {
  display: flex;
  flex: 1;
  min-height: 0px; /* IMPORTANT: you need this for non-chrome browsers */
}

/* the container for our overflowed content */
.overflow-container {
  flex: 1;
  overflow: auto;
}

/* the overflow content itself */
.overflow-content {
  height: 2000px;
}
