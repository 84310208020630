@import "~@blueprintjs/core/lib/scss/variables";

.op-node {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid $black;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  width: 160px;
  height: 160px;
  word-break: break-word;
  padding: 8px;
}

.op-node h2 {
  margin: 8px 12px 4px 12px;
  line-height: 1em;
}

.op-node h4 {
  margin: 0px 12px 8px 12px;
  line-height: 1em;
}

.op-node ul {
  margin: 0px 12px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.selected .op-node {
  border: 1px solid $gray2;
  box-shadow: 1px 1px 12px $gray2;
}

.react-flow__edge-path {
  stroke: $gray1 !important;
}
.selected .react-flow__edge-path {
  stroke: $light-gray3 !important;
}

.react-flow__handle {
  background-color: $gray1 !important;
}

.op-node.nodrag {
  cursor: not-allowed;
}

.react-flow__node {
  cursor: move;
}
.react-flow__container {
  cursor: grab;
}
