@import "~@blueprintjs/core/lib/scss/variables";

.sct-gradient {
  background: #000000;
  background: -moz-linear-gradient(top, #000000 0%, #1e5799 99%);
  background: -webkit-linear-gradient(top, #000000 0%, #1e5799 99%);
  background: linear-gradient(to bottom, #000000 0%, #1e5799 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#1e5799',GradientType=0 );
}

.sct-dimmer {
  background-color: rgba(0, 0, 0, 0.85);
}

.sct-tooltip-danger {
  background: "green";
}

hr {
  border: 0;
  height: 1px;
  background-color: rgba(240, 250, 255, 0.35);
}

// sct-front-$color is used in Stock and Desks rowClassRules and in StylingPallete (to colorize agGrid text)
.sct-front-blue,
.sct-front-blue > .bp5-icon {
  color: $blue5 !important;
}

.sct-front-green,
.sct-front-green > .bp5-icon {
  color: $green5 !important;
}

.sct-front-orange,
.sct-front-orange > .bp5-icon {
  color: $orange5 !important;
}

.sct-front-red,
.sct-front-red > .bp5-icon {
  color: $red5 !important;
}

.sct-front-indigo,
.sct-front-indigo > .bp5-icon {
  color: $indigo5 !important;
}

.sct-front-turquoise,
.sct-front-turquoise > .bp5-icon {
  color: $turquoise5 !important;
}

.sct-front-sepia,
.sct-front-sepia > .bp5-icon {
  color: $sepia5 !important;
}
