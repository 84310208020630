.sct-fade {
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
  opacity: 0;
  visibility: hidden;
}
.sct-fade.show {
  opacity: 1;
  visibility: visible;
}
