@import "~@blueprintjs/core/lib/scss/variables";
$ag-icons-path: "../node_modules/ag-grid-community/src/styles/ag-theme-balham/icons/";

.ag-theme-balham-dark input::selection,
.ag-theme-balham-dark textarea::selection {
  background-color: rgba(220, 220, 220, 1);
}

.ag-icon .ag-icon-menu {
  color: red;
}

@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark.scss";

.ag-theme-balham-dark .ag-header-cell::after,
.ag-theme-balham-dark .ag-header-group-cell::after {
  border-right: 1px solid rgba(100, 100, 100, 0.8);
}

.ag-cell-value::after {
  right: 0;
  border-right: 1px solid rgba(80, 80, 80, 0.6);
  content: "";
  height: 100%;
  position: absolute;
  text-indent: -2000px;
  top: 0;
}

.ag-header-cell-label {
  span::after {
    bottom: 3px;
    position: relative;
    font-size: 0.8em;
    text-decoration: none;
  }
}

.ag-header-group-text {
  white-space: normal;
}
.ag-header-cell-text {
  white-space: normal;
}

.ag-root-wrapper.ag-layout-normal {
  height: 100%;
}

// CSS to display caret up or down depending of sorting (Msc sheet)
div.ag-header-cell.sct-header-sort-asc {
  .ag-cell-label-container {
    .ag-header-cell-label {
      span::before {
        content: "▲ ";
      }
    }
  }
}
div.ag-header-cell.sct-header-sort-desc {
  .ag-cell-label-container {
    .ag-header-cell-label {
      span::before {
        content: "▼ ";
      }
    }
  }
}
//

div.ag-header-cell.sct-header-a {
  .ag-cell-label-container {
    .ag-header-cell-label {
      span::after {
        content: " A";
      }
    }
  }
}

div.ag-header-cell.sct-header-ae {
  .ag-cell-label-container {
    .ag-header-cell-label {
      span::after {
        content: " A/E";
      }
    }
  }
}
// https://sass-lang.com/documentation/modules/color
// global variable inherited from ag-theme-balham-dark.scss above
div.ag-header-cell.sct-forecast,
div.ag-header-cell.sct-header-e {
  .ag-cell-label-container {
    .ag-header-cell-label {
      span::after {
        content: " E";
      }
    }
  }
}

div.ag-header-cell.sct-fy {
  color: adjust-color($ag-header-foreground-color, $red: -10, $green: -10, $blue: +10, $alpha: 0.8);
}
div.ag-header-cell.sct-fy.sct-forecast {
  // Used by Stocks in FY forecast Header
  color: rgba(110, 203, 250, 0.945);
}

.ag-row-focus.ag-row-selected {
  background-color: $blue3 !important;
}

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: $dark-gray1 !important;
}

.ag-column-hover {
  background-color: $dark-gray1;
}

.sct-cell {
  text-align: right;
}

.sct-header-ae {
  color: adjust-color($ag-header-foreground-color, $red: -25, $green: -5, $blue: +5, $alpha: 1);
}
.sct-forecast,
.sct-header-e {
  color: adjust-color($ag-header-foreground-color, $red: -50, $green: -10, $blue: +10, $alpha: 1);
}

// To center column title,  add ' headerClass: "sct-center-header" ' to the columnDef
.sct-ag-center-header * {
  justify-content: center;
}
.sct-ag-medium-text-header * {
  font-size: 14px;
}

.sct-overwritten-periods {
  //Color for NGTF overwritten_periods
  color: adjust-color($ag-header-foreground-color, $red: -5, $green: -50, $blue: +10, $alpha: 1);
}

.sct-fy {
  background: #505d6f4a; // oldColor :  #273a5a2a
  font-weight: bold;
  border-right: 1px solid rgba(224, 224, 224, 0.568) !important;
  border-left: 1px solid rgba(224, 224, 224, 0.568) !important;
}

.sct-row-error {
  background: $red1 !important;
}

.sct-row-warning {
  background: $orange1 !important;
}

.sct-row-error.ag-row-hover {
  background: $red3 !important;
}
.sct-row-warning.ag-row-hover {
  background: $orange3 !important;
}

.sct-focus1 {
  background: #617075d7 !important;
  // background: $gray1 !important;
  font-weight: bold !important;
}

.sct-focus2 {
  background: #494c5ac7 !important;
  font-weight: bold !important;
}

.sct-focus3 {
  font-weight: bold !important;
}

.sct-focus4 {
  background: #494c5a6e !important;
}

.sct-empty-row {
  background: #5c5c5cc9 !important;
}

.sct-italic {
  font-style: italic !important;
}

.sct-bold {
  font-weight: bold !important;
}

.sct-underline {
  text-decoration: underline !important;
}

// Styles for Consensus
.sct-border {
  border-right: 1px solid rgba(224, 224, 224, 0.568) !important;
  border-left: 1px solid rgba(224, 224, 224, 0.568) !important;
}

.sct-starred {
  span::before {
    content: "*";
  }
}

.sct-outThreshold {
  color: #adadadbe !important;
  background: #6161612c !important;
}

.sct-excluded {
  color: #adadadbe !important;
  background: #6161612c !important;
  span::before {
    content: "-";
  }
}

.sct-positive-value {
  color: $forest3 !important;
  // span::before {
  //   content: "+" !important;
  // }
}
.sct-negative-value {
  color: $vermilion4 !important;
}
// END Styles for Consensus

// Color for Msc Context Menu when sorting is active
.sct-sort-active {
  color: $blue5 !important;
}
