// Here are CSS for Edit modal of TF nexGen sheet

// Override CSS of library react-sortable-tree

.rstcustom__row {
  width: 100%;
  overflow: hidden;
}
.rstcustom__rowContents {
  width: 100%;
  overflow: hidden;
}
.rstcustom__rowLabel {
  width: 100%;
  padding: 0 !important;
}

// Customs CSS
.sct-tf-modal {
  width: 100%;
  height: 100%;
}
.sct-edit-flow-modal {
  width: 40%;
}

.sct-modal-content {
  display: flex;
  min-height: 80vh;
  width: 100%;
  justify-content: center;
}

.sct-row-items {
  min-width: 40%;
  margin-top: 16px;
}

.sct-border-div {
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px 3px;
  padding: 0.5rem;
}

.sct-row-detail {
  min-width: 50%;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sct-row-kind-label {
  text-align: center;
  font-size: 20px;
}

.sct-row-kind-button {
  margin: 24px;
  min-height: 120px;
  min-width: 220px;
  font-size: 20px;
}

.sct-item-fields {
  justify-content: center;
}

.sct-styling-palette {
  width: 100% !important;
}

.sct-formulaBuilder-card {
  margin: 0 8px;
  padding: 4px;
  margin-bottom: 1px;
}

// add by autoComplete
.no-suggestions {
  color: #999;
  padding: 0.5rem;
}
.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}
.suggestions li {
  padding: 0.5rem;
}
.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

// NGTF/EditRow: Disable all tree actions when there are changes in a node which have not been saved
.sct-tree-disabled {
  pointer-events: none;
  opacity: 0.4;
}
